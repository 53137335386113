<template>
  <div class="h-100 d-flex flex-column mx-auto justify-content-center align-items-center">
        <h1 class="fw-bold" style="font-size: 350px;">404</h1>
        <div class="bottom-0">
          <p class="mt-2" style="font-size: x-large;">
            The Page You were looking for does not exist. Let's get you <br>
            back <router-link to="/" class="text-underline text-secondary-theme">Home</router-link>
          </p>
        </div>
    </div>
</template>